import React, {Component} from "react";
import { Dropdown } from "semantic-ui-react";
import {withTranslation} from "react-i18next";
class LanguageToggle extends Component {
    constructor() {
        super();

        this.state = {
            lang: null
        };
    }

    componentDidMount() {
        this.setState({lang: [this.props.i18n.language]})
    }

    componentDidUpdate() {

    }

    handleChange = (event, data) => {
        if(data) {
            this.props.i18n.changeLanguage(data.value);
            this.setState({lang: [data.value]})
        }
    };

    render() {
        const {lang} = this.state;
        const languageOptions = [
            { key: "Nederlands", text: "Nederlands", value: "nl-NL" },
            { key: "English", text: "English", value: "en-US" },
            { key: "Deutsch", text: "Deutsch", value: "de-DE" }
        ];

        return (
            <Dropdown
                button
                className='icon languageToggle'
                floating
                labeled
                icon='world'
                options={languageOptions}
                search
                defaultValue={lang}
                placeholder='Select Language'
                style={{"zIndex": 5}}
                onChange={this.handleChange}
            />
        );
    }
};
export default withTranslation()(LanguageToggle);
