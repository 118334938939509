import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {withTranslation} from 'react-i18next';
import './login.scss';
import authService from "../services/authService";
import LanguageToggle from "../components/languageToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
const eye = <FontAwesomeIcon icon={faEye} />;

class Login extends Component {
    constructor(props) {
        super(props);
        this.loginRef = React.createRef(null);

        this.state = {
            error: null,
            name: navigator.platform,
            email: "",
            password: "",
            loggedInLocations: null,
            passwordVisible: false
        }
    }

    componentDidMount() {
        const {history, i18n} = this.props;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const demo = urlParams.get('demo');
        const lang = urlParams.get('lang');

        switch(lang) {
            case 'en': {
                i18n.changeLanguage('en-US')
                break;
            }
            case 'nl': {
                i18n.changeLanguage('nl-NL')
                break;
            }
            case 'de': {
                i18n.changeLanguage('de-DE')
                break;
            }
        }

        const user = authService.getCurrentUser();
        if(user && !demo) {
            history.push('/player')
        }

        if(demo && (demo === "easy" || demo === "true")) {
            this.startDemo(false);
        } else if (demo && demo === "pro") {
            this.startDemo(true);
        }
    }

    startDemo = (interactive) => {
        const {history} = this.props;

        authService.loginDemo(interactive).then((success) => {
            if(success) {
                history.push('/player')
            }
        }).catch(error => {
            if(error.response) {
                const data = error.response.data;
                if(data && data.activeSystems) {
                    this.setState({loggedInLocations: data.activeSystems})
                }
                this.setState({error : {code: error.response ? error.response.status : null, message: (data && data.message) ? data.message : "Er gaat iets mis, probeer het later opnieuw."}})
            } else {
                this.setState({error: {code: 1004, message: "Er gaat iets mis, probeer het later opnieuw."}})
            }
        });
    }
    login = (event, overwriteId) => {
        const {history} = this.props;

        event?.preventDefault();
        const {email, password, name} = this.state;
        if(!email && !password) {
            this.setState({ error: {code: 1000, message: "Vul a.u.b. een email-adres en wachtwoord in."}})
            return;
        }

        if(!email) {
            this.setState({ error: {code: 1001, message: "Vul a.u.b. een email-adres in."}})
            return;
        }

        if(!password) {
            this.setState({ error: {code: 1002, message: "Vul a.u.b. een wachtwoord in."}})
            return;
        }

        if(!name) {
            this.setState({ error: {code: 1003, message: "Vul a.u.b. een apparaatnaam in waaraan je deze speler kunt herkennen."}})
            return;
        }

        authService.login(email, password, name, overwriteId).then((success) => {
            if(success) {
                history.push('/player')
            }
        }).catch(error => {
            if(error.response) {
                const data = error.response.data;
                if(data && data.activeSystems) {
                    this.setState({loggedInLocations: data.activeSystems})
                }
                this.setState({error : {code: error.response ? error.response.status : null, message: (data && data.message) ? data.message : "Er gaat iets mis, probeer het later opnieuw."}})
            } else {
                this.setState({error: {code: 1004, message: "Er gaat iets mis, probeer het later opnieuw."}})
            }
        });
    };

    renderError = (code) => {
        const { t } = this.props;

        switch(code) {
            case 404:
                return t('not_correct')
            case 401:
                return t('not_correct')
            case 500:
                return t('something_went_wrong')
            case 405:
                return t('no_license')
            case 601:
                return t('logout_system')
            case 1000:
                return t('email_password_missing')
            case 1001:
                return t('email_missing')
            case 1002:
                return t('password_missing')
            case 1003:
                return t('playername_missing')
            case 1004:
                return t('something_went_wrong')
        }
    };

    renderLink = (code) => {
        const { t } = this.props;

        switch(code) {
            case 404:
                return (<a
                    href="https://www.bcmstore.com/nl/mijn-account/"
                    title={t('create_account')}>{t('create_account')}</a>)
            case 401:
                return (<a
                    href="https://www.bcmstore.com/nl/mijn-account/lost-password/"
                    title={t('password_lost')}>{t('password_lost')}</a>)
            case 405:
                return (<a
                    href="https://bcmstream.com/bcm-stream-easy/"
                    title={t('subscribe')}>{t('subscribe')}</a>)
        }
    };

    togglePasswordVisiblity = () => {
        this.setState({passwordVisible: !this.state.passwordVisible});
    };

    render() {
        const { t } = this.props;
        const { error, loggedInLocations, passwordVisible, password } = this.state;

        return(
            <div className="loginPage">
                {loggedInLocations ?
                <><div className={"overwriteContainer"}>
                    <h3>{t('max_reached')}</h3>
                    {loggedInLocations.map((location) => {
                        return (<div onClick={() => this.login(null, location.id)} className="overwriteBtn">{location.name}</div>)
                    })}
                </div>
                    <div className="blur" onClick={() => this.setState({loggedInLocations: null})}></div>
                </> : null}
                <div className={"logoContainer"}>
                    <img className={"loginLogo"} src={"/img/BCM-Stream-headerlogo_en.svg"} />
                </div>
                    <div className="loginBox">
                        <div className="loginHeader">
                            <h3>{t('login')}</h3>
                        </div>
                        <div className={"loginBody"}>
                            <form onSubmit={this.login}>
                                <label htmlFor="email">
                                    {t('email')}</label>
                                <input name="email" type="email" placeholder={t('email')}
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({email: e.target.value})
                                }} className="input"></input>

                                <label htmlFor="password">{t('password')}</label>
                                <div className={"passwordBox"}><input name="password" type={passwordVisible ? 'text' : 'password'} placeholder={t('password')}
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({password: e.target.value})
                                }} className="input"></input>
                                {password.length > 0 ? <i onClick={this.togglePasswordVisiblity}>{eye}</i> : null}</div>
                                <label htmlFor="name">
                                    {t('playername')}</label>
                                <input name="name" type="text"
                                       value={this.state.name}
                                       placeholder={t('playername_placeholder')}
                                       style={this.state.wrong ? {boxShadow: '0 0 0 1px red'} : {}} onChange={(e) => {
                                    this.setState({name: e.target.value})
                                }} className="input"></input>

                                <button type="submit" className="loginBtn">{t('login')}</button>
                                <LanguageToggle />
                            </form>
                        </div>
                    </div>
                    <div className={"demoBox"}>
                        <div className="demoHeader">
                            <h3>{t('demo')}</h3>
                        </div>
                        <div className={"demoBody"}>
                            <p>{t('demo_text')}</p>
                            <button onClick={() => this.startDemo(false)} className={"demoBtn"}>{t('demo_start_easy')}</button>
                            <button onClick={() => this.startDemo(true)} className={"demoBtn"}>{t('demo_start_pro')}</button>
                        </div>
                    </div>
                    { error ? (
                        <div className="errorBar" role="alert" onClick={() => this.setState({error: null})}>
                            <div className="inner">
                                <li>
                                    <strong>{t('oops')}</strong> {this.renderError(error.code)} {this.renderLink(error.code)}
                                </li>
                            </div>
                            <a href="#" className="close"></a></div>
                    ) : null}
                </div>
        )
    }
};

export default withRouter(withTranslation()(Login));
